<template>

<div @scroll="handleScroll">

  <HeroSection ref="heroSection" id="heroSection"/>
  <AboutSection id="aboutSection"/>
  <ServicesSection id="serviceSection"/>
<TimelineSection id="timelineSection"/>
  <ProjectSection id="projectSection"/>
  <ContactSection id="contactSection"/>


    <div class="floating-dots" v-if="!isOnHeroSection && !isOnSmallScreen">
      <div v-for="(section, index) in sections" :key="index" 
           class="dot-container">
        <div :class="['dot', { active: isActive(section.id) }]"
             @mouseover="showSectionName(section.name)"
             @mouseleave="hideSectionName"
             @click="scrollToSection(section.id)">
        </div>
        <div class="section-name" v-if="hoveredSection === section.name">
          {{ section.name }}
        </div>
      </div>
  
  </div>
    <!--
  <nav class="navbar" v-if="isOnSmallScreen && !isOnHeroSection">
      <ul>
        <li v-for="(section, index) in sections" :key="index">
          <a :href="'#' + section.id">{{ section.name }}</a>
        </li>
      </ul>
  </nav> -->


</div>

<footer>
    <p>&copy; 2024 Brian Mulei. All rights reserved.</p>
  </footer>
</template>

<script>

import HeroSection from '@/components/HeroSection.vue';
import AboutSection from '@/components/AboutSection.vue';
import ServicesSection from '@/components/ServicesSection.vue';
import TimelineSection from '@/components/TimelineSection.vue';

import ProjectSection from '@/components/ProjectSection.vue';
import ContactSection from '@/components/ContactSection.vue';
import { ref } from 'vue';

export default({

  components:{
    HeroSection,
    AboutSection,
    ServicesSection,
    TimelineSection,
    ProjectSection,
    ContactSection
  },
  data() {
    return {
      hoveredSection: '',
      sections: [
        { id: 'heroSection', name: 'Intro' },
        { id: 'aboutSection', name: 'About' },
        {id: 'serviceSection', name:'Services'},
        {id: 'timelineSection', name:'History'},
        {id: 'projectSection', name:'Projects'},
        {id: 'contactSection', name:'Contact '}
        // Add more sections as needed
      ],
      isOnHeroSection: true,
      isOnSmallScreen: window.innerWidth <= 768,
      activeSection: '',
      currentYear:ref(new Date().getFullYear),


    };
  },
  methods: {
    showSectionName(name) {
      this.hoveredSection = name;
    },
    hideSectionName() {
      this.hoveredSection = '';
    },
    scrollToSection(id) {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    },
    handleScroll() {
      const heroSection = this.$refs.heroSection;
      const heroSectionRect = heroSection.$el.getBoundingClientRect();
      this.isOnHeroSection = heroSectionRect.bottom > 50;

      this.sections.forEach(section => {
        const element = document.getElementById(section.id);
        if (element) {
          const rect = element.getBoundingClientRect();
          const isInView = rect.top <= window.innerHeight && rect.bottom >= 0;
          if (isInView) {
            this.activeSection = section.id;
          }
        }
      });


    }
  },
  computed: {
    isActive() {
      return (sectionId) => this.activeSection === sectionId;
    }
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll(); // Initial check
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
     handleResize() {
      this.isOnSmallScreen = window.innerWidth <= 768;
    }
  
})


</script>


<style scoped>
.app-container {
  position: relative;
}

.floating-dots {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
 
  z-index: 1000; /* Ensure it's above other content */
  animation: fadeInScaleUp 1s ease-out; /* Apply the animation here */

}

.dot-container {
  position: relative;
  margin-bottom: 20px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}
.dot.active {
  background-color: darkblue; /* Highlight the active dot */
}


.section-name {
  position: absolute;
  right: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  top: -20px;
  white-space: nowrap;
}

.navbar {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  z-index: 1000; /* Ensure it's above other content */
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar li {
  margin: 0 15px;
}

.navbar a {
  color: white;
  text-decoration: none;
}

.navbar a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .floating-dots {
    display: none;
  }

  .navbar {
    display: block;
  }
}

/* Keyframes for fade-in and scale-up animation */
@keyframes fadeInScaleUp {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


footer {
  padding-top: 2%;
  text-align: center;
  padding: 1em 0;
  background: linear-gradient(45deg, #6a00f4, #00f4ff); /* Gradient from the hero section */
  color: white;
}

 
</style>
