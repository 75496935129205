<template>
    <section class="services-section">
      <h2>What I Offer</h2>
      <div class="gradient-line"></div>
      <div class="services-container">

        <div class="service-column">
          <div class="service-content">
            <div class="service-icon">
              <i class="fas fa-laptop-code"></i> <!-- Example icon -->
            </div>
            <div class="service-text">
              <h3>Web Development</h3>
              <p>Your website is the window to your business, and its presentation affects how visitors interact with it. I focus on creating clear, beautiful interfaces that ensure a smooth user experience and accurately reflect your brand.</p>
            </div>
          </div>
        </div>

        <div class="service-column">
          <div class="service-content">
            <div class="service-icon">
              <i class="fas fa-mobile-alt"></i> <!-- Example icon -->
            </div>
            <div class="service-text">
              <h3>Mobile Development</h3>
              <p>At times, your business may require a specialized mobile application to meet specific needs or enhance user engagement.
                I develop high quality android apps that offer a smooth experience, tackle your unique requirements, and keep your customers coming back.                
                </p>
            </div>
          </div>
        </div>
     
        <div class="service-column">
          <div class="service-content">
            <div class="service-icon">
              <i class="fas fa-server"></i> <!-- Example icon -->
            </div>
            <div class="service-text">
              <h3>Backend Systems Architect</h3>
              <p>The systems behind your app or website handle critical tasks, whether serving customers or supporting internal operations, to ensure smooth and efficient business performance. Because of this, they need to be both reliable and intelligent. I focus on everything backend-related, from designing efficient databases and deploying scalable infrastructure to delivering specialized business intelligence for smarter decision-making.
              </p>  
              </div>
          </div>
        </div>
      </div>
    </section>
  </template>

<script setup>
import { ref, onMounted } from 'vue';

const servicesSection = ref(null);
const serviceColumns = ref([]);

const handleIntersection = (entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('animate');
      // Also add animation class to each column if needed
      serviceColumns.value.forEach(col => col.classList.add('animate'));
    }
  });
};

onMounted(() => {
  const observer = new IntersectionObserver(handleIntersection, {
    threshold: 0.1
  });

  if (servicesSection.value) {
    observer.observe(servicesSection.value);
  }

  // Observe each column for animation
  serviceColumns.value = document.querySelectorAll('.service-column');
  serviceColumns.value.forEach(col => observer.observe(col));
});
</script>


<style scoped>
.services-section {
  position: relative;
  background: url('../assets/desk.jpg') repeat fixed 100%;
  background-size: cover;
  background-color: rgba(133, 130, 127, 0.711);
  background-blend-mode: multiply;
  padding: 60px 20px;
  color: white;
  text-align: center;
  overflow: hidden;
 
}

.services-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.gradient-line {
  width: 80px;
  height: 4px;
  background: linear-gradient(45deg, #6a00f4, #00f4ff); /* Gradient from the hero section */
  margin: 20px auto;
}
h2{
    animation: fadeIn 2s forwards; /* Fade-in animation for columns */

}
.services-container {
  display: flex;
  justify-content: space-between; /* Space columns equally */
  flex-wrap: wrap;
  animation: fadeIn 2.5s forwards; /* Fade-in animation for columns */

}

.service-column {
  flex: 1;
  max-width: 30%;
  margin: 0 10px; /* Space out columns */
  padding: 20px;
  box-sizing: border-box;
  position: relative;

}

@keyframes fadeIn {
  from {
    opacity: 0;
     transform: translateY(20px); /* Start from below */
  }
  to {
    opacity: 1;
     transform: translateY(0);   
}

}

.service-content {
  display: flex;
  align-items: center;
}

.service-icon {
  font-size: 3rem;
  margin-right: 20px; /* Space between icon and text */
}

.service-text {
  text-align: justify;
}

@media (min-width: 768px) {
.service-column:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  background-color: white;
  transform: translateX(50%);
}
}
/* .service-column:first-child::after {
  display: none; /* Remove divider for the first column 
} 
*/

.service-column h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.service-column p {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .services-container {
    flex-direction: column;
  }

  .service-column {
    max-width: 100%;
    margin: 0;
    border-bottom: 1px solid white;
    padding-bottom: 30px;
  }

  .service-column:not(:last-child) {
    border-bottom: 1px solid white; /* Divider between columns on small screens */
  }
  
}
</style>
  