<template>
    <div class="timeline-section">
      <h2>History</h2>
      <div class="gradient-line"></div>
      <div class="journey-container">
  <p class="journey-text">
{{introText}}  </p>
</div>


      <div class="timeline-container">
        <div class="timeline-line"></div>
        <!-- Loop through experienceItems to create timeline entries -->
        <div v-for="(item, index) in experienceItems" :key="index" class="timeline-item">
          <div class="timeline-icon">
            <i :class="item.icon"></i> <!-- Use FontAwesome icons or any other icon library -->
          </div>
          <div class="timeline-card">
            <h3>{{ item.title }}</h3>
            <h4>{{ item.company }} - {{ item.date }}</h4>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>

      <div class="cv-button-container">
  <a href="/files/BM_Mobile.pdf" target="_blank" class="cv-button">View My CV</a>
</div>


    </div>
  </template>
  
  
  <script setup>
  import { ref, onMounted } from 'vue';
 
  
  const  introText="I discovered a passion for coding in school, where I first discovered my interest in technology and honed my skills through various projects and coursework. After graduating in 2022, I entered the professional workforce and began applying my knowledge in real-world scenarios. Currently, I am seeking new employment opportunities while actively working on freelance and personal projects to continue growing my expertise and contributing to innovative solutions.";
 
  const experienceItems = ref([
    {
      title: "Software Engineer",
      company: "Medbook Kenya",
      date: "August 2023 - August 2024",
      description: "Collaborated within a team to plan, develop, and create mobile applications to meet client requirements. Successfully created the mobile app : AphiaApp.",
       icon: "fas fa-laptop-code" // FontAwesome laptop code icon

    },
    {
      title: "Mobile Developer",
      company: "Usalama Technologies",
      date: "June 2022 - August 2023",
      description: "Designed, developed, tested and released the rental management mobile app: Kodi.",
      icon: "fas fa-mobile-alt" // FontAwesome search icon
    },

 
  ]);
  
  const handleIntersection = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('animate');
      }
    });
  };
  
  onMounted(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1
    });
  
    const timelineItemsEl = document.querySelectorAll('.timeline-item');
    timelineItemsEl.forEach(item => observer.observe(item));
  });
  </script>
  



  <style scoped>
  /* Main section styling */
  .timeline-section {
    position: relative;
    padding: 60px 20px;
    background: url('../assets/mountain.jpg') no-repeat center center; /* Background image */
    background-size: cover;
    background-color: rgba(174, 164, 164, 0.842);
    background-blend-mode: multiply;

    color: white;
    text-align: center;
  }
  .journey-container {
  text-align: center;
  margin: 40px auto;
  max-width: 800px;
}

.journey-text {
  font-size: 1rem;
  line-height: 1.6;
  color: white;
   padding: 20px;
  border-radius: 8px;
  text-align: justify;
}

.cv-button-container {
  text-align: center;
  margin: 20px auto;
}

.cv-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  background-color: #00ffff; /* Example color */
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cv-button:hover {
    color: #ccc;
  background-color: #ff00ff; /* Example hover color */
}

  .timeline-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .gradient-line {
    width: 80px;
    height: 4px;
    background: linear-gradient(45deg, #6a00f4, #00f4ff); /* Gradient from the hero section */
    margin: 20px auto;
  }
  
  /* Container for timeline */
  .timeline-container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  /* Timeline line */
  .timeline-line {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 4px;
    background: linear-gradient(to bottom, #ff00ff, #00ffff); /* Gradient line */
    transform: translateX(-50%);
  }
  
  /* Timeline item */
  .timeline-item {
    position: relative;
    display: flex;
    margin: 20px 0;
    padding: 20px;
    /* background: rgba(255, 255, 255, 0.1); */
    border-radius: 8px;
    opacity: 0; /* Start as invisible */
    transform: translateY(20px); /* Start position */
  }
  
  /* Position icons on the line */
  .timeline-item .timeline-icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5%; 
    width: 30px;
    height: 30px;
    padding: 3%;
    background: rgb(105, 105, 105);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px; /* Bigger icons */
  }
  
  /* Alternating sides */
  .timeline-item:nth-child(even) {
    flex-direction: row-reverse;
  }
  
  .timeline-item:nth-child(even) .timeline-card {
    margin-left: 20px;
  }
  
  .timeline-item:nth-child(odd) .timeline-card {
    margin-right: 20px;
  }
  
  .timeline-card {
    width: 45%;
    padding: 20px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    text-align: justify;
  }
  
  .timeline-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .timeline-card h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #ccc;
  }
  
  .timeline-card p {
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    .timeline-line {
      left: 10%; /* Position the line to the left */
    }
  
    .timeline-container {
      padding: 0;
    }
  
    .timeline-item {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 5%;
      margin-right: 0;
    }
  
    .timeline-card {
      width: calc(100% - 40px); /* Ensure cards fit without overlapping */
      margin: 0 20px;
     }
  
    .timeline-item .timeline-icon {
    position: absolute;
    left: 6%;
    transform: translateX(-50%);
    margin-top: 5%; 
    width: 30px;
    height: 30px;
    padding: 5%;
    background: rgb(105, 105, 105);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px; /* Bigger icons */
  }
    /* .timeline-item .timeline-icon {
      top: 10px; 
      width: 36px;
      height: 36px;
      font-size: 28px;
    } */
    
  }
  
  /* Add animation for timeline items */
  .timeline-item.animate {
    opacity: 1;
    transform: translateY(0); /* End at original position */
    transition: opacity 1s ease-out, transform 1s ease-out; /* Smooth transition */
  }
  </style>
  