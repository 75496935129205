<template>
  <div class="projects-section">
    <h2 class="section-heading">My Recent Projects</h2>
    <div class="gradient-line"></div>

    <div class="projects-container">
      <div class="projects-list">
        <div v-for="(project, index) in projects" :key="index" class="project">
          <h3 class="project-title">{{ project.title }}</h3>

          <div class="image-grid">
            <img v-for="(image, imgIndex) in project.images" :src="image" :alt="'Image ' + (imgIndex + 1)"
              :key="imgIndex" class="project-image" @click="toggleFullscreen(image)" />
          </div>

          <div v-if="selectedImage" class="overlay">
      <v-img
        class="mt-12"
        :src="selectedImage"
        alt=""
        height="95vh"
        contain
        dark
        @click.stop="selectedImage = null"
     >
      </v-img>
    </div>

          <p class="project-description">{{ project.description }}</p>

          <div class="tech-chips">
            <span v-for="(tech, idx) in project.technologies" :key="idx" class="chip"
              @mouseover="highlightChip" @mouseout="unhighlightChip">{{ tech }}</span>
          </div>

          <div class="project-links">
            <a :href="project.githubLink" target="_blank" class="btn btn-github"><i class="fab fa-github"></i> GitHub</a>
            <a :href="project.liveDemoLink" target="_blank" class="btn btn-live-demo"><i class="fas fa-external-link-alt"></i> Live Demo</a>
          </div>
        </div>
      </div>

      <div class="more-info">
        <p class="more-text">And there are more on the way!</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedImage: null,
      projects: [
        {
          title: "Weather App",
          description: "Web based application for viewing the weather forecast.",
          images: [require('@/assets/weather.png'), require('@/assets/weather_2.png') ],
          technologies: ["Vue.js", "Open Weather Api", "Firebase"],
          githubLink: "https://github.com/Brian-Mulei/vue-weather-app",
          liveDemoLink: "https://weather.mulei.dev",
        },
        {
          title: "Resume Site",
          description: "This exact site where I showcase my skills and abilities.",
          images: [require('@/assets/site_1.png'), require('@/assets/site_2.png')],
          technologies: ["Vue.js", "Firebase", "Tailwind CSS"],
          githubLink: "https://github.com/Brian-Mulei/vue-web-portfolio",
          liveDemoLink: "https://mulei.dev",
        },
      ],
    };
  },
  methods: {
    toggleFullscreen(elem) {
      this.selectedImage = elem;
    },
  },
};
</script>

<style scoped>
.projects-section {
  padding: 20px;
  background-color: #222;
  color: #ffffff;
  text-align: left;
}
.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 95%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.section-heading {
  font-size: 2rem;
  text-align: center;
}

.gradient-line {
  width: 100px;
  height: 3px;
  background: linear-gradient(45deg, #6a00f4, #00f4ff); /* Gradient from the hero section */
  margin: 0 auto;
}

.projects-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
}

.projects-list {
  margin-left: 5%;
  margin-top: 5%;
  width: 60%;
}

.project {
  margin-bottom: 40px;
}

.project-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 50px;
}

.project-image {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-description {
  margin: 15px 0;
}

.tech-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.chip {
  background-color: #64d9e6;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.875rem;
  color: white;
}

.chip:hover {
  background: linear-gradient(45deg, #6a00f4, #00f4ff); /* Gradient from the hero section */
  color: white;
}

.project-links {
  margin-top: 15px;
}

.project-links .btn {
  margin-right: 10px;
  padding: 10px;
  text-decoration: none;
  color: white;
  border-radius: 5px;
}

.btn-github {
  background-color: #333;
}

.btn-live-demo {
  background-color: #007bff;
}

.more-info {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
 }

.more-text {
  font-family: 'Looney Tunes', cursive;
  font-size: 2.7rem;
  color: #ffffff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .projects-container {
    flex-direction: column;
  }
 
  .projects-list {
    margin-left: 0%;
  margin-top: 0%;
    width: 100%;
  }

  .more-info {
    width: 100%;
    display: flex; /* Show on small screens */
    justify-content: center;
    margin-top: 20px;
  }
  .more-text {
  font-family: 'Looney Tunes', cursive;
  font-size: 1.7rem;
  color: #ffffff;
}

}
</style>
