// Styles
import "@fortawesome/fontawesome-free";
import "@mdi/font/css/materialdesignicons.css";
import { aliases, fa } from "vuetify/iconsets/fa";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  icons: {
    defaultSet: "fa",
    aliases,
    sets: {
      fa,
    },
  },
  theme: {
    themes: {
      light: {
        primary: "#0060df", // Custom primary color
        secondary: "#fbfbfe", // Custom secondary color
        accent: "#fd6f53", // Custom accent color
        font: "#000000",
      },
      dark: {
        primary: "#17ed90", // Custom primary color for dark theme
        secondary: "#243133", // Custom secondary color for dark theme
        accent: "#12cdea", // Custom accent color for dark theme
        fontcolor: "#ffffff",
      },
    },
  },
});
