<template>
  <v-app>
    <v-main>
      <router-view/>
     </v-main>
  </v-app>
</template>

<script>
 
export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
<style>
html,
body {
  background: linear-gradient(180deg, #000000, #1A237E);
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>