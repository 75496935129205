<template>
  <div class="contact-me">
    <h2 class="section-heading">Contact Me</h2>
    <div class="gradient-line"></div>
    <p class="form-intro"> I’m always eager to hear your thoughts, ideas, and questions. Whether you want to discuss a
      project or just chat about tech, you can reach me through any of the links below. You can also check out my GitHub
      to see my latest work.
    </p>

    <div class="contact-icons">

      <a href="mailto:brianmulei0@gmail.com" class="icon fade-in">
        <i class="fas fa-envelope"></i> <span>Email</span>
      </a>

      <a href="tel:+254729468786" class="icon fade-in">
        <i class="fas fa-phone"></i> <span>Phone</span>
      </a>

      <a href="https://www.linkedin.com/in/brian-mulei-48b79219b/" target="_blank" rel="noopener noreferrer"
        class="icon fade-in">
        <i class="fab fa-linkedin"></i> <span>LinkedIn</span>
      </a>
      <a href="https://github.com/Brian-Mulei" target="_blank" rel="noopener noreferrer" class="icon fade-in">
        <i class="fab fa-github"></i> <span>GitHub</span>
      </a>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ContactMe',


}
</script>

<style scoped>
.contact-me {
  margin: 0 auto;
  padding-left: 110px;
  padding-right: 110px;
  padding-bottom: 2%;
  background: #222;
  /* Gradient from the hero section */
  color: #ffffff;
}

.section-heading {
  font-size: 2rem;
}

.gradient-line {
  width: 100px;
  height: 3px;
  background: linear-gradient(45deg, #6a00f4, #00f4ff);
  /* Gradient from the hero section */
  margin: 0 auto;
  margin-bottom: 25px;

}


h2 {
  text-align: center;
  margin-bottom: 5px;
}



.contact-icons {

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

}

.icon {

  text-decoration: none;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  margin: 10px;
  flex: 1;
  min-width: 100px;

}



@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in forwards;
}

.fade-in:nth-child(1) {
  animation-delay: 0.1s;
}

.fade-in:nth-child(2) {
  animation-delay: 0.3s;
}

.fade-in:nth-child(3) {
  animation-delay: 0.5s;
}

.fade-in:nth-child(4) {
  animation-delay: 0.7s;
}


@media (max-width: 600px) {
  .contact-icons {
    flex-direction: column;
    align-items: center;
  }

  .icon {
    margin: 10px 0;
  }
}
</style>